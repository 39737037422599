.sidebar-container {
  z-index: 9;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: calc(100vh - 50px - 1px);
  color: #333333;
}
.sidebar-container .sidebar {
  width: 32px;
  flex-direction: column;
}
.sidebar-container .sidebar .nav-item a {
  color: #FAFAFA;
}
.sidebar-container .sidebar-max {
  width: 300px;
  flex-direction: column;
  background-color: var(--accent-color);
  font-family: 'Barlow Semi Condensed', sans-serif;
  position: relative;
  flex-wrap: nowrap;
}
.sidebar-container .sidebar-max .close-sidebar {
  position: absolute;
  top: 0;
  right: 20px;
  padding: 0px;
  height: 32px;
  width: 32px;
}
.sidebar-container .sidebar-max .close-sidebar:hover,
.sidebar-container .sidebar-max .close-sidebar:active {
  background-color: var(--highlight-color);
}
.sidebar-container .sidebar-max .close-sidebar svg {
  font-size: 2em !important;
  width: 100%;
  height: 100%;
}
.sidebar-container .sidebar-max .menu-container {
  overflow: auto;
}
.sidebar-container .sidebar-max .menu-container .nav-item .menu-title {
  margin-left: 10px;
  font-size: 1.15em;
  display: inline-block;
}
.sidebar-container .sidebar-max .menu-container .nav-item .menu-title:hover {
  text-decoration: underline !important;
}
.sidebar-container .sidebar-max .menu-container .nav-item .separator {
  width: 10px;
  margin: 10px 20px;
  background: #666;
  border-top: 3px solid #666666e1;
}
.sidebar-container .sidebar-max .menu-container .nav-item .pub-title {
  color: #6d6c6c;
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'Barlow Condensed', sans-serif;
  cursor: pointer;
}
.sidebar-container .sidebar-max .menu-container .nav-item .pub-title.collapsed ~ .nav-item {
  display: none;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item {
  font-weight: 400;
  max-width: 300px;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item a {
  color: #333;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item > .nav-item {
  font-weight: 300;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item > .nav-item.level-2 {
  margin-left: 0.5rem;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.s-false a {
  cursor: default;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.s-false a .menu-title {
  cursor: pointer;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.s-false a svg {
  cursor: pointer;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.s-true a {
  cursor: pointer;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.disabled .menu-title {
  color: #999;
  cursor: default;
  text-decoration: none !important;
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link {
  line-height: 1.5em;
  padding-top: 0.25rem;
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link .icon-collapsed {
  color: #3F3F3F;
  display: none;
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link.collapsed .icon-expanded {
  display: none;
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link.collapsed .icon-collapsed {
  display: inline;
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link.selected {
  background: var(--highlight-color);
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link.content-title:hover {
  background: var(--highlight-color);
}
.sidebar-container .sidebar-max .menu-container .nav-item .nav-link:hover {
  text-decoration: underline !important;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.level-1 .nav-link {
  padding-left: 15px;
  padding-top: 0.05rem;
  padding-bottom: 0.2rem;
  font-size: 0.9em;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.level-2 .nav-link {
  padding-left: 40px;
}
.sidebar-container .sidebar-max .menu-container .nav-item .toc-item.level-3 .nav-link {
  padding-left: 50px;
}
.sidebar-container .sidebar-max .address-container {
  font-size: 0.7em;
  line-height: 0.4em;
  padding: 2vh 1vw 0 1vw;
  flex-grow: 1;
}
#popover-sidebar-share,
#popover-basic,
#popover-login {
  z-index: 11;
  left: -5px !important;
}
.avatar {
  width: 32px;
  height: 32px;
}
.avatar img {
  width: inherit;
  height: inherit;
  border-radius: 100%;
}
.visual-container {
  position: relative;
  font-family: 'Barlow Semi Condensed';
}
.visual-container .visual {
  width: 100%;
  object-fit: cover;
  max-height: inherit;
}
.visual-container.linked {
  cursor: pointer;
}
.visual-container h1.card-title {
  position: absolute;
  top: 50%;
  bottom: 50%;
  font-style: italic;
  color: white;
  background: #333;
  min-height: min-content;
  padding: 5px 10px;
}
.visual-container svg {
  position: absolute;
  top: 0;
  left: 0;
}
.share-visual,
.link {
  position: absolute;
  bottom: 3vw;
  background: var(--accent-color);
  padding: 5px;
  display: flex;
  font-size: 1.4em;
  width: 2.2vw;
  height: 2.2vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.share-visual {
  left: 3vw;
}
.link {
  right: 3vw;
}
.carousel-indicators {
  align-items: center;
}
.carousel-indicators > li {
  width: 10px;
  height: 10px;
  border-top: none;
  border-bottom: none;
  background-color: #CDCCCB;
  padding: 0px !important;
  border-radius: 100%;
}
.carousel-indicators > li.active {
  background: #FFDD00;
  width: 1.5vw;
  height: 1.5vw;
}
.landing-page .board.carousel-visuals {
  max-height: calc(100vh - 10px);
  overflow: hidden;
  position: inherit;
}
.article .carousel-visuals {
  width: 100%;
}
.carousel-control-next,
.carousel-control-prev {
  display: none;
  opacity: 1;
}
.carousel-control-next span,
.carousel-control-prev span {
  background-image: url('./assets/carousel-nav.svg');
  background-repeat: no-repeat;
  padding: 1vw;
}
.carousel-control-prev span {
  transform: rotate(180deg);
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  zoom: 120%;
  opacity: 1;
}
.board-control-bar {
  z-index: 10;
  height: 32px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  top: 50px;
  width: inherit;
}
.board-control-bar .board-title {
  font-size: 0.9em;
  margin: 0px 5px;
  font-weight: 400;
}
.board-control-bar .board-title a {
  color: initial;
}
.board-control-bar .control {
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.board-control-bar .control:hover,
.board-control-bar .control:active {
  background-color: var(--accent-color);
}
.board-control-bar .control.close {
  opacity: 1;
}
.board-control-bar .control.disabled {
  cursor: default;
  pointer-events: none;
}
.board-control-bar .right-controls {
  margin-left: auto;
  margin-right: 5px;
  display: flex;
  flex-flow: row-reverse;
}
.board-control-bar .right-controls .control {
  margin-right: 10px;
}
.board-control-bar .right-controls .search {
  order: -2;
  margin-right: 0px;
}
.board {
  background-color: #F7F7F7;
  font-family: 'Barlow Semi Condensed';
  overflow: auto;
  min-width: calc(100vw - 400px);
  color: #333333;
}
.board-container {
  flex: 1;
}
.board-container:not(:first-child) {
  margin-left: 20px;
}
.board-container.deck {
  display: table;
  width: calc((100vw - 32px)*0.72);
}
.board-container.deck .share-link {
  position: sticky;
  top: calc(100vh - 146px);
  z-index: 11;
  padding: 0px;
  margin-left: 20px;
  width: min-content;
}
.board-container.deck .share-link + .card:first-of-type {
  margin-top: -32px;
}
.board-container.deck .board {
  max-height: calc(100vh - (50px + 32px + 18px));
  min-width: auto;
  overflow-x: hidden;
  width: inherit;
}
.board-container.deck .board .card {
  background-color: #f7f7f7;
  border: 0px;
}
.board-container.deck .board .card pattern,
.board-container.deck .board .card .card-body a {
  position: relative;
  display: inline-flex;
  color: inherit;
  line-height: 1.2em;
  font-weight: 600;
  cursor: pointer;
}
.board-container.deck .board .card pattern:not(.download).board-container.deck .board .card pattern::before,
.board-container.deck .board .card pattern:not(.download).board-container.deck .board .card .card-body a::before,
.board-container.deck .board .card .card-body a:not(.download).board-container.deck .board .card pattern::before,
.board-container.deck .board .card .card-body a:not(.download).board-container.deck .board .card .card-body a::before {
  content: '';
  display: block;
  position: absolute;
  top: -0.4em;
  width: 2em;
  left: 0px;
  background-image: url('./assets/tie.svg');
  height: 0.6em;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}
.board-container.deck .board .card pattern.open-link::before,
.board-container.deck .board .card .card-body a.open-link::before {
  background-color: var(--accent-color);
}
.board-container.deck .board .card pattern:hover,
.board-container.deck .board .card .card-body a:hover {
  text-decoration: none;
  background: var(--accent-color);
}
.board-container.deck .board .card pattern.open-pattern-link::before,
.board-container.deck .board .card .card-body a.open-pattern-link::before {
  background-color: var(--accent-color);
}
.board-container.deck .board .card .card-body {
  line-height: 1.9em;
}
.board-container.deck .board .card .card-body p.story,
.board-container.deck .board .card .card-body .card-text.story {
  font-family: 'Libre Baskerville', serif;
  padding-left: 18vw;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background: white;
  display: block;
  font-style: italic;
  font-size: 0.95em;
  line-height: 2.5em;
}
.board-container.deck .board .card .card-body p strong,
.board-container.deck .board .card .card-body .card-text strong,
.board-container.deck .board .card .card-body p.hash3,
.board-container.deck .board .card .card-body .card-text.hash3 {
  margin-top: 3vh;
  margin-bottom: 0px;
  font-weight: 500;
}
.board-container.deck .board .card .card-body p.hash3,
.board-container.deck .board .card .card-body .card-text.hash3 {
  font-size: 1.6em;
  display: none;
}
.board-container.deck .board .card .card-body p strong,
.board-container.deck .board .card .card-body .card-text strong {
  font-weight: 600;
}
.board-container.deck .board .card .card-body span.card-text {
  padding: 0px;
}
.board-container.deck .board .card .card-body li.numbered {
  list-style: none;
}
.board-container.deck .board .card .card-body.summary {
  padding-top: 0px;
}
.board-container.deck .board .card .card-body.summary .card-text {
  font-weight: 800;
  font-size: 1.2em;
  padding-right: 22vw;
}
.board-container.deck .board .card .card-body.author,
.board-container.deck .board .card .card-body.circles {
  padding-top: 0px;
  padding-bottom: 0px;
}
.board-container.deck .board .card .card-body.author .card-text,
.board-container.deck .board .card .card-body.circles .card-text {
  display: flex;
  font-size: 0.9em;
  line-height: 1.9em;
  font-weight: lighter;
}
.board-container.deck .board .card .card-body.author .card-text .name,
.board-container.deck .board .card .card-body.circles .card-text .name {
  text-transform: capitalize;
}
.board-container.deck .board .card .card-body.author .card-text .updated,
.board-container.deck .board .card .card-body.circles .card-text .updated {
  color: #aaa;
}
.board-container.deck .board .card .card-body.author .card-text .boxed,
.board-container.deck .board .card .card-body.circles .card-text .boxed {
  border: solid 1px #333;
  padding: 0.25vh 0.5vw;
  border-right-width: 0px;
}
.board-container.deck .board .card .card-body.author .card-text .boxed:last-child,
.board-container.deck .board .card .card-body.circles .card-text .boxed:last-child {
  border-right-width: 1px;
}
.board-container.deck .board .card .card-body.author .card-text .name,
.board-container.deck .board .card .card-body.circles .card-text .name,
.board-container.deck .board .card .card-body.author .card-text .published,
.board-container.deck .board .card .card-body.circles .card-text .published,
.board-container.deck .board .card .card-body.author .card-text .updated,
.board-container.deck .board .card .card-body.circles .card-text .updated {
  border-right: solid 1px #333;
  padding: 0px 1vw;
  margin: auto 0;
}
.board-container.deck .board .card .card-body.author .card-text .name:last-child,
.board-container.deck .board .card .card-body.circles .card-text .name:last-child,
.board-container.deck .board .card .card-body.author .card-text .published:last-child,
.board-container.deck .board .card .card-body.circles .card-text .published:last-child,
.board-container.deck .board .card .card-body.author .card-text .updated:last-child,
.board-container.deck .board .card .card-body.circles .card-text .updated:last-child {
  border-right: 0px;
}
.board-container.deck .board .card.ck-cards-container,
.board-container.deck .board .card.cp-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.board-container.deck .board .card.ck-cards-container .card,
.board-container.deck .board .card.cp-cards-container .card {
  flex: 5;
  border-radius: 0px;
}
.board-container.deck .board .card.ck-cards-container .card.knowledge-item,
.board-container.deck .board .card.cp-cards-container .card.knowledge-item {
  min-width: 20%;
  flex: 2;
  color: #555;
  font-weight: 100;
  background-color: var(--accent-color);
}
.board-container.deck .board .card.ck-cards-container .card.knowledge-item .card-text,
.board-container.deck .board .card.cp-cards-container .card.knowledge-item .card-text {
  padding: 0px;
  background: transparent;
  font-size: 0.7em;
  line-height: 1.2em;
}
.board-container.deck .board .card.ck-cards-container .card.knowledge-item .carousel-indicators,
.board-container.deck .board .card.cp-cards-container .card.knowledge-item .carousel-indicators {
  bottom: 1vw;
}
.board-container.deck .board .card.ck-cards-container .card.knowledge-item .carousel-indicators li,
.board-container.deck .board .card.cp-cards-container .card.knowledge-item .carousel-indicators li {
  width: 0.5vw;
  height: 0.5vw;
  padding-left: 0px;
  padding-right: 0px;
}
.board-container.deck .board .card.ck-cards-container .card.knowledge-item .carousel-indicators li.active,
.board-container.deck .board .card.cp-cards-container .card.knowledge-item .carousel-indicators li.active {
  width: 0.8vw;
  height: 0.8vw;
}
.board-container.deck .board .card.cp-cards-container {
  flex-direction: row-reverse;
}
.board-container.deck .board .share-link {
  position: sticky;
  top: calc(100vh - 146px);
  z-index: 11;
  padding: 0px;
  margin-left: 20px;
  width: min-content;
}
.board-container.deck .board .share-link + .card:first-of-type {
  margin-top: -32px;
}
.board-container.deck .board .share-link .share {
  background: var(--accent-color);
  padding: 5px;
  display: flex;
  font-size: 1em;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  z-index: 8;
}
.board-container.deck .board .visual-container {
  max-height: calc(100vh - 50px - 32px);
}
.board-container.deck .board .carousel-indicators {
  bottom: 2vh;
}
.board-container.deck .board .card,
.board-container.deck .board .card-body,
.board-container.deck .board .tm-footer > .card-body p {
  padding-left: 0px;
  padding-right: 0px;
}
.board-container.deck .board .card-text,
.board-container.deck .board .tm-footer > .card-body,
.board-container.deck .board li {
  padding-left: 15vw;
  padding-right: 15vw;
}
.board-container.deck .board .tm-footer > .card-body .card-text {
  font-size: 0.7em;
  margin-bottom: 0.8vh;
  line-height: 1.2em;
  display: table;
  border-top: solid 1px #777;
  border-bottom: solid 1px #777;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  color: #777;
  font-weight: 100;
}
.board-container.pattern {
  width: 70vw;
}
.board-container.fullscreen {
  height: 100vh;
  max-width: none;
  margin-top: -50px;
  z-index: 82;
  position: fixed;
  margin-left: 0px;
}
.board-container.fullscreen.deck .board .share-link {
  top: calc(100vh - 100px);
}
.board-container.fullscreen + .board-container {
  margin-left: 100vw;
}
.board-container.fullscreen .board {
  max-height: 100vh;
  width: initial;
}
.sidebar-closed .board-container.fullscreen {
  width: calc(100vw - 32px);
}
.sidebar-open .board-container.fullscreen {
  width: calc(100vw - 332px);
}
.landing-page-container .brand-container a {
  width: auto;
}
.landing-page-container .landing-page {
  position: relative;
}
.landing-page-container .landing-page .visual-container {
  max-height: calc(97vh);
  overflow: hidden;
}
.landing-page-container .landing-page .visual-container .card-img-overlay {
  color: white;
  margin-top: 50px;
}
.landing-page-container .landing-page .carousel .visual-container {
  max-height: calc(100vh);
}
.landing-page-container .landing-page .board.stacked-visuals {
  overflow: visible;
}
.landing-page-container .landing-page.board-container {
  overflow: visible;
  margin-right: 0px;
}
.landing-page-container .landing-page .yin-yang-board .board {
  min-width: auto;
}
.landing-page-container .landing-page .yin-yang-board .carousel-visuals {
  width: calc(100% * 65/100);
  display: inline-block;
}
.landing-page-container .landing-page .yin-yang-board .carousel-visuals .carousel-item .visual-container {
  height: calc(900vw*65/1600);
  background-size: cover;
  background-repeat: no-repeat;
}
.landing-page-container .landing-page .yin-yang-board > .visual-container {
  width: calc(100% * 35/100);
  display: inline-block;
  background-position-x: 100%;
  height: calc(900vw*65/(16*100));
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-page-container .landing-page .yin-yang-board > .visual-container svg {
  transform: scale(1.83) translate(22%, 22%);
  position: absolute;
  top: 0;
  left: 0;
}
.landing-page-container .landing-page .yin-yang-board .card-img {
  border-radius: 0px;
}
.landing-page-container .content-area {
  max-height: 100vh;
  flex-direction: column;
  overflow-y: auto;
}
.landing-page-container .content-area .reader-area {
  flex-direction: column;
}
.landing-page-container.sidebar-open .yin-yang-board .carousel-visuals {
  width: 65 '%';
  display: inline-block;
}
.landing-page-container.sidebar-open .yin-yang-board > .visual-container {
  display: inline-block;
  max-height: calc(100vh - 90px);
}
.landing-page-container.sidebar-open .yin-yang-board .card-img {
  border-radius: 0px;
}
.landing-page.grid {
  display: flex;
  max-width: calc(100vw - 30px);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
}
.landing-page.grid .tile {
  width: calc(100%/3);
  height: calc(((100vw - 32px)/3) * 9 / 16);
  display: flex;
  color: #333;
}
.landing-page.grid .tile .poster {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.landing-page.grid .tile.category {
  flex-direction: row;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.landing-page.grid .tile.category:hover {
  border: solid 1px var(--accent-color);
}
.landing-page.grid .tile.category:first-child,
.landing-page.grid .tile.category:nth-child(2) {
  width: 50%;
  height: calc((((100vw - 32px)/2) * 9)/16);
}
.landing-page.grid .tile.category a > .header {
  position: absolute;
  z-index: 1;
}
.landing-page.grid .tile.category .article {
  width: 1.5%;
  height: 100%;
  display: inline-block;
  background-position: center;
}
.landing-page.grid .tile.category .article .header {
  display: none;
}
.landing-page.grid .tile.category .article:nth-of-type(2) {
  flex-grow: 1;
  min-width: 95%;
  border: 0;
}
.landing-page.grid .tile.category .article:nth-of-type(n+3) {
  opacity: 0.8;
}
.landing-page.grid .tile > a {
  color: #333;
  text-decoration: none;
  width: 100%;
  display: flex;
}
.landing-page.grid .tile .header {
  font-size: 0.62em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 100%;
  background: transparent;
}
.landing-page.grid .tile .header a {
  color: #333;
}
.landing-page.grid .tile .header b {
  background: #333;
  padding: 2%;
  width: min-content;
}
.landing-page.grid .tile .header b span {
  font-weight: 500;
}
.landing-page.grid .tile .header b span:last-child {
  font-size: 1.6em;
  font-weight: 500;
  line-height: 1em;
  white-space: nowrap;
}
.landing-page.grid .tile.article {
  margin: 0;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
  width: calc(100%/3);
  background-size: cover;
}
.landing-page.grid .tile.article.yt {
  background-size: 16%, cover;
  background-repeat: no-repeat, no-repeat;
}
.landing-page.grid .tile.article.podcast {
  background-size: 25% 25%;
  background-repeat: no-repeat;
}
.landing-page.grid > .tile.article:hover {
  border: solid 1px var(--accent-color);
}
.landing-page.grid > .tile.article:nth-child(1),
.landing-page.grid > .tile.article:nth-child(2) {
  width: 50%;
  height: calc((((100vw - 32px)/2) * 9 / 16) - 2px);
}
.landing-page.grid a.tile a,
.landing-page.grid a.tile span {
  display: inline-block;
  text-decoration: none;
}
.landing-page.grid a.tile a:hover,
.landing-page.grid a.tile a:active {
  text-decoration: underline;
}
.sidebar-open .landing-page.grid .tile.article,
.sidebar-open .landing-page.grid .tile.category {
  height: calc(((100vw - 32px - 300px) / 2) * 9 / 16);
}
.sidebar-open .landing-page.grid .tile.article:nth-child(n+3),
.sidebar-open .landing-page.grid .tile.category:nth-child(n+3) {
  height: calc(((100vw - 32px - 300px) / 3) * 9 / 16);
}
.sidebar-open .landing-page.grid .tile.category .tile.article {
  height: 100%;
}
.sidebar-open .landing-page.grid .tile.category .tile.article:not(:nth-child(2)) {
  width: 1%;
}
.article.board .card:nth-child(3) .nugget {
  padding-top: 0;
}
.article.board .visual-container + .card .nugget {
  font-size: 0.82em;
  margin: 0 5px 0 5%;
  font-weight: 400;
}
.article.board .card-header {
  background-color: inherit;
  border-bottom: 0px;
  padding-left: 15vw;
  padding-right: 15vw;
}
.article.board .card-header .card-title {
  color: #333333;
  font-family: 'Barlow Semi Condensed';
  font-weight: 600;
  font-size: 2em;
  line-height: 1.5em;
  border: 0px;
}
.article.board .card-body {
  font-weight: 400;
}
.article.board .card-body .card-title {
  color: #333333;
  font-family: 'Barlow Semi Condensed';
}
.article.board .card-body .card-text {
  font-size: 1.25em;
  line-height: 1.2em;
  min-height: 3vh;
  margin-bottom: 0;
}
.article.board .card-body .card-text .regular {
  line-height: 1.5em;
}
.article.board .card-body .card-text ita {
  color: #888;
  line-height: 1.5em;
}
.article.board .card-body.nugget {
  font-weight: 500;
}
.article.board .card-body.podcast {
  display: block;
  margin-top: -6vw;
  padding-top: 0;
  width: 100%;
  text-align: center;
}
.board-container.deck .pattern.board {
  max-height: calc(100vh - 32px - 50px);
}
.board-container.deck .pattern.board .card-header {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  padding: 0px;
  border: 0px;
}
.board-container.deck .pattern.board .card-header .card-title {
  color: #333333;
  font-family: 'Barlow Semi Condensed';
  font-weight: 1000;
  font-size: 2.2em;
  line-height: 1.5em;
  background-color: #f7f7f7;
  border: 0px;
  flex-grow: 3;
  flex-basis: 3;
  padding: 3vh 5vw;
  margin-bottom: 0px;
}
.board-container.deck .pattern.board .card-header .header-meta {
  flex-grow: 1;
  padding: 4vh 1vw;
  max-width: 20%;
  background-color: #efefef;
}
.board-container.deck .pattern.board .card-header .header-meta .pattern-category {
  font-size: 0.7em;
  line-height: 1em;
  padding-top: 1vh;
  font-weight: 600;
}
.board-container.deck .pattern.board .card-header .header-meta .rating {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
}
.board-container.deck .pattern.board .card-header .header-meta .rating .dot,
.board-container.deck .pattern.board .card-header .header-meta .rating .circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 0.25vw;
}
.board-container.deck .pattern.board .card-header .header-meta .rating .dot {
  background: #333;
}
.board-container.deck .pattern.board .card-header .header-meta .rating .circle {
  border: solid 1px #333;
}
.board-container.deck .pattern.board .card {
  background-color: #f7f7f7;
}
.board-container.deck .pattern.board .card .card-body {
  line-height: 1.9em;
}
.board-container.deck .pattern.board .card .card-body .card-text {
  padding-left: 5vw;
  padding-right: 5vw;
}
.board-container.deck .pattern.board .card .card-body li.numbered {
  list-style: none;
}
.board-container.deck .pattern.board .card li {
  padding-left: 5vw;
  padding-right: 5vw;
}
.board-container.deck .pattern.board .visual-container {
  max-height: calc(100vh - 50px - 32px);
}
.board-container.deck .pattern.board .carousel-indicators {
  bottom: 2vh;
}
.board-container.deck .pattern.board .card,
.board-container.deck .pattern.board .card-body,
.board-container.deck .pattern.board .tm-footer > .card-body p {
  padding-left: 0px;
  padding-right: 0px;
}
.board-container.deck .pattern.board .card-text,
.board-container.deck .pattern.board .tm-footer > .card-body {
  padding-left: 5vw;
  padding-right: 5vw;
}
.ps-container {
  max-width: 300px;
  font-size: 0.6em;
  color: #aaa;
  flex-direction: column;
  height: fit-content;
  padding: 1.25rem 0;
}
.ps-container .ps-row {
  display: flex;
  flex-direction: row;
  background-color: #e7e7e7;
  min-height: 8vw;
}
.ps-container .ps-row:last-child .patterns {
  padding-bottom: 2vh;
}
.ps-container .ps-row .scale {
  transform: rotate(90deg);
  text-transform: capitalize;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -35px;
  margin-right: -35px;
  text-align: center;
  padding-right: 2vh;
  padding-left: 2vh;
  font-weight: 100;
  width: 100px;
  height: auto;
}
.ps-container .ps-row .pattern-container {
  background-color: #efefef;
  flex-grow: 1;
  border-left: solid 1px #ccc;
  display: flex;
}
.ps-container .ps-row .pattern-container .patterns {
  padding-left: 0.5vw;
  font-weight: 600;
  align-self: center;
}
.ps-container .ps-row .pattern-container .patterns .pattern {
  padding: 1vh 1vw;
}
.ps-container .ps-row .pattern-container .patterns .pattern a {
  color: inherit;
}
.ps-container .ps-row .pattern-container .patterns .pattern a:hover,
.ps-container .ps-row .pattern-container .patterns .pattern a:focus {
  text-decoration: none;
}
h3.exhibit-title {
  height: 50px;
  display: flex;
  color: black;
  font-family: 'Barlow Semi Condensed';
  background: var(--accent-color);
  white-space: nowrap;
  padding: 16px 32px;
  top: 0px;
  z-index: 10;
  max-width: 100%;
}
h3.exhibit-title em {
  padding: 0 3px;
}
.share {
  display: flex;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.share-visual {
  left: 1vw;
}
.pf-popover {
  border-radius: 0px;
  background-color: var(--accent-color);
  min-width: calc(300px + 32px);
  border: 0px;
  z-index: 9;
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.pf-popover .arrow {
  display: none;
}
.pf-popover[x-placement=top] {
  margin-bottom: auto !important;
}
.pf-popover[x-placement=bottom] {
  margin-top: auto !important;
}
.pf-popover .popover-header {
  background-color: transparent;
  font-size: 0.8em;
  color: #555656;
  font-weight: 400;
  border-bottom: solid 3px black;
  padding-top: 7%;
  padding-bottom: 7%;
}
.pf-popover form {
  padding-right: 20%;
  font-size: 0.8em;
}
.pf-popover form .btn-pf {
  margin-top: 10%;
  width: 100%;
}
.pf-popover form .btn-pf:last-child {
  margin-bottom: 10%;
}
.pf-popover form .form-err-msg {
  color: red;
  padding-left: 0.75vw;
}
.pf-popover .form-control {
  border: none;
  background: transparent;
  border-bottom: solid 2px black;
  border-radius: 0px;
  font-size: 1em;
  color: #555656;
}
.pf-popover .form-control:active,
.pf-popover .form-control:focus {
  box-shadow: none;
}
.tm-footer hr {
  border-top: solid 5px black;
  width: 10vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 0px;
}
.tm-footer .card-body .card-text {
  font-size: 0.7em;
  margin-bottom: 0.8vh;
  line-height: 1.2em;
  display: table;
  border-top: solid 1px #777;
  border-bottom: solid 1px #777;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  color: #777;
  font-weight: 200;
}
.btn-pf {
  border-radius: 0px;
  font-size: 1em;
  font-weight: bolder;
  text-transform: uppercase;
}
.btn-pf:active,
.btn-pf:focus,
.btn-pf.focus {
  box-shadow: none;
}
.btn-pf.primary {
  background: black;
  color: white;
}
.btn-pf.secondary {
  border: solid 2px black;
}
.alt .tooltiptext {
  visibility: hidden;
  max-width: 100%;
  background-color: var(--accent-color);
  color: black;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
  transition: opacity 0.3s;
  font-size: 0.7em;
  line-height: 1.2em;
  width: max-content;
  font-weight: 300;
}
.alt:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
:root {
  --podcast-btn-base-size: 2.6vw;
}
.page .rhap_container {
  width: 80%;
  margin-left: 10%;
  padding: 0.5% 2%;
  font-size: 0.9em;
  background-color: transparent;
  box-shadow: none;
}
.page .rhap_container .rhap_horizontal-reverse .rhap_controls-section {
  margin-right: var(--podcast-btn-base-size);
}
.page .rhap_additional-controls {
  display: none;
}
.page .rhap_main-controls .rhap_rewind-button,
.page .rhap_main-controls .rhap_forward-button {
  display: none;
}
.page .rhap_progress-section {
  flex-grow: 2;
}
.page .rhap_progress-indicator {
  background-color: #333;
}
.page .rhap_progress-bar-show-download,
.page .rhap_volume-bar {
  background-color: #fff;
}
.page .rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 0;
}
.page .rhap_volume-button svg {
  color: var(--accent-color);
  border-radius: 100%;
  background-color: transparent;
}
.page .rhap_play-pause-button svg {
  color: var(--accent-color);
}
.page .rhap_volume-container {
  flex-basis: 100%;
}
.page .rhap_volume-indicator {
  background: #333;
  box-shadow: none;
  border: solid 1px var(--accent-color);
}
.page .rhap_play-pause-button {
  width: var(--podcast-btn-base-size);
  height: var(--podcast-btn-base-size);
  font-size: var(--podcast-btn-base-size);
}
.page .rhap_volume-button {
  width: calc(2 * var(--podcast-btn-base-size)/3);
  height: calc(2 * var(--podcast-btn-base-size)/3);
  font-size: calc(2 * var(--podcast-btn-base-size)/3);
}
.page .rhap_progress-indicator {
  width: calc(var(--podcast-btn-base-size)/2);
  height: calc(var(--podcast-btn-base-size)/2);
  font-size: calc(var(--podcast-btn-base-size)/2);
  margin-top: calc(0 - var(--podcast-btn-base-size)/16);
  top: calc(0vw - var(--podcast-btn-base-size)/8);
  border: solid 1px var(--accent-color);
}
.page .rhap_volume-indicator {
  width: calc(var(--podcast-btn-base-size)/3);
  height: calc(var(--podcast-btn-base-size)/3);
  font-size: calc(var(--podcast-btn-base-size)/3);
  top: calc(0vw - var(--podcast-btn-base-size)/12);
}
.page .rhap_volume-bar {
  height: calc(var(--podcast-btn-base-size) / 6);
  background-color: var(--accent-color);
}
.page .rhap_progress-bar {
  height: calc(var(--podcast-btn-base-size) / 4);
  background-color: var(--accent-color);
}
.page .rhap_current-time,
.page .rhap_total-time {
  background-color: var(--accent-color);
  padding: 1%;
}
@media only screen and (max-width: 900px) {
  .board-container:not(:first-child) {
    margin-left: 2vw;
  }
  .board-container.deck .board {
    min-height: calc(100vh - 50px - 32px);
    width: 82vw;
  }
  .board-container.deck .board .share-link {
    top: calc(100vh - 114px);
    left: 0px;
    margin-left: 0px;
  }
  .board-container.deck .board .article {
    width: 100% !important;
  }
  .board-container.deck .board .card.ck-cards-container .card {
    flex: auto;
  }
  .board-container.deck .board .card.ck-cards-container .card.knowledge-item,
  .board-container.deck .board .card.ck-cards-container .card.ps-container {
    max-width: 300px;
    margin: 0 auto;
    display: none;
  }
  .board-container.deck .board .card .card-header.card-title {
    font-size: 1.2em;
  }
  .board-container.deck .board .card .card-body.summary .card-text {
    font-size: 0.7em;
  }
  .board-container.deck .board .card .card-body.circles .card-text {
    font-size: 0.6em;
  }
  .board-container.deck .board .card .card-body li {
    padding-right: 8vw;
    padding-left: 8vw;
    font-size: 0.8em;
    line-height: 1.5em;
  }
  .board-container.deck .board .card .card-body .card-text {
    font-size: 0.8em;
    padding-right: 8vw;
    padding-left: 8vw;
  }
  .board-container.deck .board .card .card-body .card-text.regular {
    padding-right: 8vw;
  }
  .board-container.deck .board .card .card-body .card-text strong {
    font-size: 1em;
  }
  .board-container.deck .board .card .card-body .card-text.hash3 {
    font-size: 1.3em;
  }
  .board-container.deck .board .card .card-body .card-text.story {
    font-size: 0.8em;
    line-height: 2.3em;
  }
  .board-container.deck .board .card .card-body.author .card-text .name,
  .board-container.deck .board .card .card-body.author .card-text .published,
  .board-container.deck .board .card .card-body.author .card-text .updated {
    line-height: 1.2em;
    text-align: center;
    font-size: 0.7em;
  }
  .board-container.deck .board .card .card-body.author .card-text .name:last-child,
  .board-container.deck .board .card .card-body.author .card-text .published:last-child,
  .board-container.deck .board .card .card-body.author .card-text .updated:last-child {
    border-right: 0px;
  }
  .board-container.deck .board.article .card-text,
  .board-container.deck .board.article .tm-footer > .card-body,
  .board-container.deck .board.article .card > .card-title.card-header {
    padding: 1vh 1vw;
  }
  .board-container.pattern {
    width: calc((100vw - 32px)*0.94);
  }
  .board-container.pattern .card .card-header {
    flex-direction: column;
  }
  .board-container.pattern .card .card-header .header-meta {
    padding: 0 2vw 3vh 2vw;
    max-width: 100%;
    background: transparent;
  }
  .board-container.pattern .card .card-header .card-title {
    font-size: 1.5em;
    line-height: 1.8em;
  }
  .tm-footer {
    padding-bottom: 16px;
  }
  .board-container.deck .board .card.cp-cards-container {
    flex-direction: row-reverse;
  }
  .board-container.deck .board .card.cp-cards-container.scale-first {
    flex-direction: column;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card {
    min-width: 9vw;
    max-width: 100vw;
    z-index: 1;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card.scale-only {
    flex: 0;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card.scale-only .ps-row {
    align-self: flex-end;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card.scale-only + .card {
    display: flex;
    flex-grow: 3;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card .ps-row {
    height: 10vh;
  }
  .board-container.deck .board .card.cp-cards-container .ps-container.card .ps-row .pattern-container.hidden {
    width: 0px;
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .landing-page.grid .tile.category,
  .landing-page.grid .tile.article {
    width: 100%;
    height: calc(((100vw - 32px) * 9)/16) !important;
  }
  .landing-page.grid .tile.category:first-child,
  .landing-page.grid .tile.article:first-child,
  .landing-page.grid .tile.category:nth-child(2),
  .landing-page.grid .tile.article:nth-child(2) {
    width: 100%;
  }
  .sidebar-open .landing-page.grid .tile.category,
  .sidebar-open .landing-page.grid .tile.article {
    height: calc(((100vw - 32px - 300px) * 9)/16) !important;
  }
  .sidebar-open .landing-page.grid .tile.category:first-child,
  .sidebar-open .landing-page.grid .tile.article:first-child,
  .sidebar-open .landing-page.grid .tile.category:nth-child(2),
  .sidebar-open .landing-page.grid .tile.article:nth-child(2),
  .sidebar-open .landing-page.grid .tile.category:nth-child(n+3),
  .sidebar-open .landing-page.grid .tile.article:nth-child(n+3) {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  :root {
    --podcast-btn-base-size: 5.2vw;
  }
  .board-container.deck .board-title {
    font-size: 0.76em;
  }
  .board-container.deck .article.board .card.ck-cards-container .card {
    flex: auto;
  }
  .board-container.deck .article.board .card .card-header {
    padding-right: 4vw;
    padding-left: 4vw;
  }
  .board-container.deck .article.board .card .card-header .card-title {
    font-size: 1.05em;
  }
  .board-container.deck .article.board .card .card-body.summary .card-text {
    font-size: 0.9em;
  }
  .board-container.deck .article.board .card .card-body.circles .card-text {
    font-size: 0.7em;
  }
  .board-container.deck .article.board .card .card-body.podcast {
    margin-top: -8vw;
  }
  .board-container.deck .article.board .card .card-body li {
    padding-right: 3vw;
    padding-left: 3vw;
    font-size: 0.9em;
    line-height: 1.5em;
  }
  .board-container.deck .article.board .card .card-body .card-text {
    font-size: 0.9em;
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .board-container.deck .article.board .card .card-body .card-text strong {
    font-size: 1em;
  }
  .board-container.deck .article.board .card .card-body .card-text.hash3 {
    font-size: 1.3em;
  }
  .board-container.deck .article.board .card .card-body .card-text.story {
    font-size: 0.7em;
    line-height: 2em;
  }
  .board-container.deck .article.board .card .card-body.author .card-text .name,
  .board-container.deck .article.board .card .card-body.author .card-text .published,
  .board-container.deck .article.board .card .card-body.author .card-text .updated {
    border-right: solid 1px #333;
    line-height: 1.5em;
    text-align: center;
    font-size: 0.7em;
  }
  .board-container.deck .article.board .card .card-body.author .card-text .name:last-child,
  .board-container.deck .article.board .card .card-body.author .card-text .published:last-child,
  .board-container.deck .article.board .card .card-body.author .card-text .updated:last-child {
    border-right: 0px;
  }
  .board-container.deck .article.board .card-text,
  .board-container.deck .article.board .tm-footer > .card-body,
  .board-container.deck .article.board .card > .card-title.card-header {
    padding: 1vh 1vw;
  }
  .knowledge-item.card {
    padding: 5vw !important;
  }
  .knowledge-item.card .card {
    background: #d0d0d05e;
  }
  .knowledge-item.card .card .card-text {
    padding: 0 5vw !important;
  }
  .knowledge-item.card .visual-container {
    background-color: #d0d0d05e !important;
    padding: 5vw !important;
  }
  .landing-page-container .content-area .landing-page {
    max-height: calc(100vh - 50px);
  }
  .landing-page-container .content-area .landing-page .yin-yang-board .carousel-visuals {
    display: block;
    width: 100%;
  }
  .landing-page-container .content-area .landing-page .yin-yang-board .carousel-visuals .visual-container {
    height: calc(900vw/16);
  }
  .landing-page-container .content-area .landing-page .yin-yang-board > .visual-container {
    display: block;
    width: 100%;
    height: calc(900vw/16);
    background-position-x: 0;
  }
  .landing-page-container .content-area .landing-page .yin-yang-board > .visual-container svg {
    transform: none;
  }
  .landing-page-container .content-area .landing-page.grid {
    margin-top: 0;
  }
  .landing-page.grid .tile .header {
    font-size: 0.6em;
    min-height: 22%;
  }
  .page .rhap_container {
    padding: 2% 2%;
    width: 90%;
    margin-left: 5%;
  }
  .page .rhap_container .rhap_horizontal-reverse .rhap_controls-section {
    margin-right: calc(var(--podcast-btn-base-size) / 4);
  }
  .page .rhap_container .rhap_horizontal-reverse .rhap_controls-section .rhap_volume-button {
    margin-right: calc(var(--podcast-btn-base-size) / 6);
  }
  .page .rhap_container .rhap_time {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  h3.exhibit-title {
    display: none;
  }
  .brand-container .brand {
    width: calc(100vw - 32px) !important;
  }
  .sidebar-open .content-area {
    margin-left: -300px;
  }
  .article.board .card.ck-cards-container .card {
    flex: auto;
  }
  .article.board .card .card-header .card-title {
    font-size: 0.9em;
  }
  .article.board .card .card-body .card-text {
    font-size: 0.9em;
  }
  .article.board .card .card-body .card-text.bold {
    font-size: 1em;
  }
  .article.board .card .card-body .card-text.hash3 {
    font-size: 1.3em;
  }
  .article.board .card .card-body .card-text.story {
    font-size: 0.8em;
    line-height: 2em;
  }
  .board.article .card-text,
  .board.article .tm-footer > .card-body,
  .board.article .card > .card-title.card-header {
    padding: 1vh 1vw;
  }
  .link {
    width: 6vw;
    height: 6vw;
  }
  .sidebar-max .nav-item .brand-link {
    background: var(--accent-color);
    display: flex;
  }
  .sidebar-max .nav-item .brand-link .brand {
    margin-left: calc((100vw - 32px - 300px)/2);
  }
  .carousel-indicators > li.active {
    width: 3vw;
    height: 3vw;
  }
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sg.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnfw.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3E.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAA.ttf) format('truetype');
}
:root {
  --accent-color: grey;
  --highlight-color: white;
}
.page {
  display: flex;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 16px;
}
.page .nav-spine {
  background-color: #333;
  width: 32px;
  height: 100vh !important;
  flex-direction: column;
}
.page .nav-spine .nav-item {
  width: 32px;
  height: 32px;
  display: flex;
}
.page .nav-spine .nav-item a {
  color: #FAFAFA;
}
.page .nav-spine .nav-item a.nav-link {
  padding: 0px 6px;
}
.page .nav-spine .nav-item .nav-link {
  padding: 8px 8px;
  display: flex;
  width: 32px;
  height: 32px;
}
.page .nav-spine .nav-item .nav-link:hover,
.page .nav-spine .nav-item .nav-link.active {
  background: var(--accent-color);
  color: #333;
}
.page .nav-spine .nav-item .nav-link.user {
  text-align: center;
  text-transform: capitalize;
  color: #333;
  flex-flow: column;
  padding: 4px 4px;
}
.page .nav-spine .nav-item .nav-link.user .avatar {
  width: 24px;
  height: 24px;
  line-height: 20px;
  background: var(--accent-color);
  border-radius: 100%;
}
.page .nav-spine .nav-item .nav-link.share-link,
.page .nav-spine .nav-item .nav-link.reach-us {
  width: 32px;
  padding: 8px;
}
.page .nav-spine .nav-item .nav-link.share-link .share,
.page .nav-spine .nav-item .nav-link.reach-us .share {
  width: 100%;
  height: 100%;
}
.page .nav-spine .nav-item:last-child {
  height: 64px;
  display: flex;
  flex-direction: column;
}
.page .nav-spine .nav-item:last-child .nav-item {
  padding: 0px;
  width: 32px;
  height: 32px;
}
.page .nav-spine .nav-item:first-child {
  height: 50px;
}
.page .nav-spine .nav-item:first-child .nav-link {
  height: inherit;
  padding-top: calc((50px - 32px)/2);
}
.content-area {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: calc(100vw - 32px);
  overflow-x: auto;
}
.content-area .reader-area {
  display: flex;
  flex-direction: row;
}
.brand-container {
  z-index: 10;
  background-color: #333333;
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  justify-content: stretch;
  width: 100%;
}
.brand-container a {
  background: var(--accent-color);
}
img.brand {
  cursor: pointer;
  height: 50px;
  background: var(--accent-color);
  width: 300px;
}
.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.main .center-stage {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}
.sidebar-closed .main {
  width: calc(100vw - 32px);
}
.sidebar-open .main .content-area {
  width: calc(100vw - 300px - 32px);
  overflow-x: auto;
}
canvas {
  pointer-events: none;
  width: 100%;
  height: 100vh !important;
}
